import "../bot.scss";
import GroupInfoOptions from "../../../components/group-info/group-info";
import TypesOfPlans from "../../../components/types-of-plans/types-of-plans";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import sha256 from "crypto-js/sha256";
import {
  getApiHealthCheck,
  getLead,
  getPlans,
  getCep,
  getProposta,
  getPaymentBoleto,
  getPaymentPix,
} from "../../../api/requests";
import useUserStore from "../../../storeModules/useUserStore";
import useConsorcioStore from "../../../storeModules/useConsorcioStore";
import {
  TipoBotEnum,
  valoresSimulacao,
  ProdutosNomes,
} from "../../../utils/constants";
import { tipoPlanoNome } from "../../../utils/constants";
import useInitialConfigStore from "../../../storeModules/useInitialConfigStore";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../../../utils/setLoadingMessage";
import { resetStoreComprarNovaCota } from "../../../storeModules/resetStoreComprarNovaCota";
import {
  TipoSimulacaoEnum,
  CategoriaPagamento,
} from "../../../utils/constants";
import ServicesPlans from "../../../components/servicesPlans/index";
import errorSteps from "./errorSteps";
import pagamentoPixSteps from "./payment/pagamentoPixSteps";
import pagamentoBoletoSteps from "./payment/pagamentoBoletoSteps";
import cepErrorSteps from "./cepErrorSteps";
import findStep from "./findStep";
import sleepAwait from "../../../utils/sleepAwait";
import addCepMask from "../../../utils/addCepMask";
import addPhoneMask from "../../../utils/addPhoneMask";
import addPhoneDatalayerMask from "../../../utils/addPhoneDatalayerMask";
import sendTag from "../../../utils/sendTag";

const fakeLoad = useInitialConfigStore.getState().fakeLoad;
const isSimulateAgain = () => {
  return useUserStore.getState().isSimulateAgain;
};

const sendDataLayerUserDataUpdate = () => {
  const {
    tipoCliente,
    cpfCnpj,
    tipoPessoa,
    nascimentoFundacao,
    nomeRazao,
    cep,
  } = useUserStore.getState().contrato;
  const { email, telefone } = useUserStore.getState().basic;

  const isPessoaFisica = tipoPessoa === 0;

  const hashedEmail = sha256(email).toString();
  const hashedTelefone = sha256(addPhoneDatalayerMask(telefone)).toString();
  const hashCpfCnpj = sha256(cpfCnpj).toString();

  sendTag({
    event: "Dados Cadastrais Atualizados",
    tipo_cliente: tipoCliente,
    email_hash: hashedEmail,
    celular_hash: hashedTelefone,
    cpf_hash: isPessoaFisica ? hashCpfCnpj : "",
    cnpj_hash: isPessoaFisica ? "" : hashCpfCnpj,
    email_cliente: email,
    telefone_cliente: addPhoneDatalayerMask(telefone),
    data_nascimento: isPessoaFisica ? nascimentoFundacao : "",
    data_fundacao: isPessoaFisica ? "" : nascimentoFundacao,
    nome_completo: isPessoaFisica ? nomeRazao : "",
    razao_social: isPessoaFisica ? "" : nomeRazao,
    cep: cep,
  });
};

const handleGetApiHealthCheck = () => {
  setLoadingMessage();

  getApiHealthCheck()
    .then(async (response) => {
      sendTag({ event: "Começar" });

      await sleepAwait(fakeLoad);

      if (response) {
        useBotControlStore.setState({
          currentStep: 2,
        });
      } else {
        useBotControlStore.setState({
          currentStep: 87,
        });
      }
    })
    .catch(() => {
      useBotControlStore.setState({
        currentStep: 87,
      });
    })
    .finally(() => {
      removeLoadingMessage();
    });
};

const handleGetLead = () => {
  setLoadingMessage();

  getLead()
    .then(async (response) => {
      const { data } = response.data;

      useUserStore.setState({
        lead: {
          LeadID: data.id,
          LeadToken: data.LeadToken,
        },
      });

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      const userNome = useUserStore.getState().basic.nome;
      const userEmail = useUserStore.getState().basic.email;
      const userTelefone = useUserStore.getState().basic.telefone;
      const userLeadID = useUserStore.getState().lead.LeadID;

      const hashedEmail = sha256(userEmail).toString();
      const hashedTelefone = sha256(
        addPhoneDatalayerMask(userTelefone)
      ).toString();

      sendTag({
        event: "Lead Save",
        email: hashedEmail,
        celular: hashedTelefone,
        lead_id: userLeadID,
        email_cliente: userEmail,
        celular_cliente: addPhoneDatalayerMask(userTelefone),
        nome: userNome,
      });

      useBotControlStore.setState({
        currentStep: findStep("plans-list-carousel-get").index,
      });

      return response;
    })
    .catch(() => {
      useBotControlStore.setState({
        currentStep: 87,
      });

      removeLoadingMessage();
    });
};

const handleGetPlans = () => {
  setLoadingMessage();

  getPlans()
    .then(async (response) => {
      const fetchedPlans = response.data.data.planos;
      const consorcioData = useConsorcioStore.getState();

      sendTag({ event: "Ver Planos" });

      if (fetchedPlans.length) {
        useConsorcioStore.setState({
          ...consorcioData,
          fetchedPlans,
        });

        await sleepAwait(fakeLoad);

        useBotControlStore.setState({
          currentStep: findStep("plans-list-select").index,
        });
      }
    })
    .catch((error) => {
      if (
        error.response.data.errors.includes(
          "Não foram encontrados planos para esta empresa"
        )
      ) {
        useBotControlStore.setState({
          currentStep: findStep("error-no-plans").index,
        });
      } else if (
        error.response.data.errors.includes(
          "Não foram encontrados planos para sua renda"
        )
      ) {
        useBotControlStore.setState({
          currentStep: findStep("error-renda-insuficiente").index,
        });
      } else {
        useBotControlStore.setState({
          currentStep: 87,
        });
      }
    })
    .finally(() => {
      removeLoadingMessage();
    });
};

const handleGetProposta = () => {
  getProposta()
    .then((response) => {
      const consorcioData = useConsorcioStore.getState();
      const { data } = response.data;

      if (data && data.propostaID) {
        useConsorcioStore.setState({
          ...consorcioData,
          fetchedProposta: data,
        });
      }
      useBotControlStore.setState({
        currentStep: 33,
      });
    })
    .catch((error) => {
      if (
        error.response.data.errors.includes("O CEP informado, não é válido!")
      ) {
        useBotControlStore.setState({
          currentStep: findStep("cep-not-found").index,
        });
        useUserStore.setState({
          cepErrorAt: "proposta",
        });
        return;
      }

      useBotControlStore.setState({
        currentStep: 87,
      });
    });
};

const handleGetCep = (errorAt = "cep-first-input") => {
  setLoadingMessage();

  getCep()
    .then(async (response) => {
      const address = response.data.data;
      const userCep = useUserStore.getState().contrato.cep;
      const userContratoData = useUserStore.getState().contrato;
      const userBasicData = useUserStore.getState().basic;
      const userLeadData = useUserStore.getState().lead;

      if (errorAt !== "update") {
        sendTag({
          event: "Cadastro CEP",
          cep: userCep,
        });
      }

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      if (!address) {
        useBotControlStore.setState({
          currentStep: findStep("cep-not-found").index,
        });
        useUserStore.setState({
          cepErrorAt: errorAt,
        });
        return;
      }

      if (address && address.cep) {
        useUserStore.setState({
          basic: {
            ...userBasicData,
          },
          lead: {
            ...userLeadData,
          },
          contrato: {
            ...userContratoData,
            bairro: address.bairro,
            cep: address.cep,
            cidade: address.cidade,
            complemento: address.complemento,
            estado: address.estado,
            logradouro: address.logradouro,
            numero: address.numero,
          },
        });
      }

      if (!address.logradouro || !address.bairro) {
        useBotControlStore.setState({
          currentStep: findStep("cep-error").index,
        });
        useUserStore.setState({
          cepErrorAt: errorAt,
        });

        return;
      }

      useBotControlStore.setState({
        currentStep: errorAt === "cep-first-input" ? 23 : 26,
      });

      return response;
    })
    .catch(() => {
      useBotControlStore.setState({
        currentStep: findStep("cep-not-found").index,
      });
      useUserStore.setState({
        cepErrorAt: errorAt,
      });
      removeLoadingMessage();
    });
};

const handleGetPaymentBoleto = () => {
  setLoadingMessage();
  const { propostaToken } = useConsorcioStore.getState().fetchedProposta;

  getPaymentBoleto({ propostaToken })
    .then(async (response) => {
      const { data } = response.data;
      const userPaymentData = useUserStore.getState().payment;

      useUserStore.setState({
        payment: { ...userPaymentData, boleto: data },
      });

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      useBotControlStore.setState({
        currentStep: 39,
      });
    })
    .catch((error) => {
      console.log("error --->", error);

      useBotControlStore.setState({
        currentStep: findStep("pagamento-boleto-tentar-novamente").index,
      });
      removeLoadingMessage();
    });
};

const handleGetPaymentPix = () => {
  setLoadingMessage();
  const { propostaToken } = useConsorcioStore.getState().fetchedProposta;

  getPaymentPix({ propostaToken })
    .then(async (response) => {
      const { data } = response.data;
      const userPaymentData = useUserStore.getState().payment;

      useUserStore.setState({
        payment: { ...userPaymentData, pix: data },
      });

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      useBotControlStore.setState({
        currentStep: 64,
      });
    })
    .catch((error) => {
      console.log("error --->", error);

      useBotControlStore.setState({
        currentStep: findStep("pagamento-pix-tentar-novamente").index,
      });
      removeLoadingMessage();
    });
};

const steps = [
  {
    sender: "bot",
    text: () => {
      const { botType } = useInitialConfigStore.getState();
      const textBox = TipoBotEnum[botType];
      return textBox.textChat1;
    },
    nextStep: 1,
  },
  {
    sender: "bot",
    text: () => {
      const { botType } = useInitialConfigStore.getState();
      const textBox = TipoBotEnum[botType];
      return textBox.textChat2;
    },
    options: [
      {
        text: "SIMULE AGORA MESMO",
        action: async () => {
          handleGetApiHealthCheck();
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Agora escolha o que <b>você deseja conquistar</b>:",
    isWaitingForFetch: true,
    useCarousel: true,
  },
  {
    sender: "bot",
    text: "Como prefere que eu te <b>chame</b>?",
    inputType: "userBasicName",
    nextStep: 4,
  },
  {
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `Muito prazer, <b>${userBasicName} :)</b>`;
    },
    action: () => {
      const { tipoTabela } = useInitialConfigStore.getState();
      const { tipoBem } = useConsorcioStore.getState();

      if (tipoTabela === "integral") {
        useBotControlStore.setState({ currentStep: 9 });
        sendTag({ event: "Plano Integral" });

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_INTEGRAL][tipoBem],
        });
      } else if (tipoTabela === "paga_metade") {
        useBotControlStore.setState({ currentStep: 91 });
        sendTag({ event: "Plano Paga Metade" });

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_PAGAMETADE][tipoBem],
        });
      } else if (tipoTabela === "completa" && tipoBem === "SERVICO") {
        useBotControlStore.setState({ currentStep: 9 });
      } else {
        useBotControlStore.setState({ currentStep: 5 });
      }
    },
  },
  {
    sender: "bot",
    text: "Opa, vamos partir pra essa conquista. Separei <b>dois planos que tenho disponíveis</b> para você escolher:",
    nextStep: 6,
  },
  {
    sender: "bot",
    text: "<b>Plano Paga Metade</b> <br/> Pague 50% do valor das parcelas até a contemplação. Quando esse dia chegar, o valor residual será diluído nas parcelas restantes.",
    nextStep: 7,
  },
  {
    sender: "bot",
    text: "<b>Plano Integral</b><br/> Pague 100% do valor das suas parcelas até o final do prazo contratado em seu plano.",
    nextStep: 8,
  },
  {
    sender: "bot",
    text: "Escolha uma das opções:",
    options: TypesOfPlans(),
  },
  {
    name: "credit-value",
    sender: "bot",
    text: "Qual é o <b>valor aproximado do crédito</b> que você precisa?",
    nextStep: 10,
  },
  {
    sender: "bot",
    inputType: "userCreditValue",
    nextStep: 11,
    text: () => {
      const { tipoPlano, tipoBem } = useConsorcioStore.getState();
      const valores = valoresSimulacao[tipoPlano]?.[tipoBem];
      return `Digite um valor entre:<br><br> <b>R$ ${valores.min.toLocaleString(
        "pt-BR",
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      )} a R$ ${valores.max.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}</b>`;
    },
    action: async () => {
      const { tipoPlano, tipoBem } = useConsorcioStore.getState();
      const valores = valoresSimulacao[tipoPlano]?.[tipoBem];

      if (valores) {
        return {
          text: `Digite um valor entre:<br /> <b>R$ ${valores.min.toLocaleString(
            "pt-BR",
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )} a R$ ${valores.max.toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}</b>`,
        };
      } else {
        return {
          text: "Não foi possível encontrar os valores para o tipo selecionado.",
        };
      }
    },
  },
  {
    sender: "bot",
    text: "Vou te mostrar planos com <b>parcelas que cabem no seu bolso</b> e que não comprometem mais de <b>30%</b> da sua renda 😉.",
    nextStep: 12,
    action: async () => {
      const uservalorCredito = useUserStore.getState().basic.valorCredito;
      sendTag({
        event: "Valor Crédito",
        credit_value: uservalorCredito,
      });
    },
  },
  {
    sender: "bot",
    text: () => {
      return isSimulateAgain()
        ? null
        : "Para isso, informe a sua <b>renda mensal</b>, incluindo <b>renda extra</b> e <b>familiar</b>, se houver.";
    },
    action: () => {
      const nextStep = isSimulateAgain()
        ? findStep("plans-list-carousel-get").index
        : findStep("basico-valor-renda").index;

      useBotControlStore.setState({
        currentStep: nextStep,
      });
    },
  },
  {
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `Legal, <b>${userBasicName}</b>! Enquanto procuro os <b>melhores planos</b> pra você, me diz uma coisinha:`;
    },
    nextStep: 14,
    action: async () => {
      const userRendaMensal = useUserStore.getState().basic.rendaMensal;
      sendTag({ event: "Renda", income_value: userRendaMensal });
    },
  },
  {
    sender: "bot",
    text: "Qual o seu melhor <b>e-mail</b>?",
    inputType: "userEmail",
    nextStep: 15,
  },
  {
    sender: "bot",
    text: "Qual o número do seu <b>celular</b> (com DDD)?",
    inputType: "userTelefone",
    nextStep: 16,
  },
  {
    sender: "bot",
    text: "",
    action: async () => {
      handleGetLead();
    },
  },
  {
    name: "plans-list-carousel-get",
    sender: "bot",
    text: () => {
      return isSimulateAgain() ? null : "Obrigado!";
    },
    isWaitingForFetch: true,
    action: () => {
      handleGetPlans();
    },
  },
  {
    sender: "bot",
    text: "Falta pouco para <b>iniciar a sua conquista</b>. Só preciso confirmar algumas informações. 😎",
    options: [
      {
        text: "SEGUIR EM FRENTE",
        nextStep: 19,
        action: async () => {
          const userBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const userBemID = useConsorcioStore.getState().selectedPlano.bemID;
          const userPlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const userValorPagamento =
            useConsorcioStore.getState().selectedPlano.planoValorPagamento;
          const userValorParcela =
            useConsorcioStore.getState().selectedPlano.planoValorParcela;
          const TaxaAdmTotal =
            useConsorcioStore.getState().fetchedPlanDetails.taxaAdmTotal;

          sendTag({
            event: "Vamos Fechar",
            valor_credito: userBemCredito, //Valor do Crédito
            bem_id: userBemID, //ID do Bem
            plano_meses: userPlanoMeses, //Número de Parcela
            valor_pagamento: userValorPagamento, //Valor primeira parcela
            valor_parcela: userValorParcela, //Valor demais parcelas
            taxa_total: TaxaAdmTotal, //Valor demais parcelas
          });
        },
      },
      {
        text: "VER OUTROS PLANOS",
        nextStep: 17,
        action: () => {
          sendTag({ event: "Simular Outro" });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Quem será o titular do plano?",
    options: [
      {
        text: "EU MESMO",
        nextStep: 20,
        action: () => {
          sendTag({ event: "Pessoa Fisica" });

          const userData = useUserStore.getState();
          const userContratoData = useUserStore.getState().contrato;

          useUserStore.setState({
            ...userData,
            contrato: {
              ...userContratoData,
              tipoPessoa: 0,
              tipoCliente: "Pessoa Física",
            },
          });
        },
      },
      {
        text: "MINHA EMPRESA",
        nextStep: 20,
        action: () => {
          sendTag({ event: "Pessoa Juridica" });

          const userData = useUserStore.getState();
          const userContratoData = useUserStore.getState().contrato;
          useUserStore.setState({
            ...userData,
            contrato: {
              ...userContratoData,
              tipoPessoa: 1,
              tipoCliente: "Pessoa Jurídica",
            },
          });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Qual o seu <b>nome completo</b>?"
        : "Digite a <b>razão social</b>";
    },
    inputType: "userContratoNomeRazao",
    nextStep: 21,
    action: async () => {
      const { tipoPessoa } = useUserStore.getState().contrato;
      const isPessoaFisica = tipoPessoa === 0;

      const propName = isPessoaFisica ? "nome" : "razao_social";
      const propNameCompleta = isPessoaFisica
        ? "nome_completo"
        : "razao_social_completa";

      const userNomeRazao = useUserStore.getState().contrato.nomeRazao;
      const hashNomeRazao = sha256(userNomeRazao).toString();

      sendTag({
        event: isPessoaFisica
          ? "Cadastro Nome Completo"
          : "Cadastro Razão Social",
        [propName]: hashNomeRazao,
        [propNameCompleta]: userNomeRazao,
      });
    },
  },
  {
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Informe o seu <b>CPF </b>"
        : "Informe o <b>CNPJ </b>";
    },
    inputType: "userCpfCnpj",
    nextStep: 22,
    action: async () => {
      const { tipoPessoa } = useUserStore.getState().contrato;
      const isPessoaFisica = tipoPessoa === 0;
      const userCpfCnpj = useUserStore.getState().contrato.cpfCnpj;
      const hashCpfCnpj = sha256(userCpfCnpj).toString();
      const propName = isPessoaFisica ? "cpf" : "cnpj";

      sendTag({
        event: `Cadastro ${isPessoaFisica ? "CPF" : "CNPJ"}`,
        [propName]: hashCpfCnpj,
      });
    },
  },
  {
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `<b>${userBasicName}</b>, qual é o seu CEP?`;
    },
    inputType: "userCep",
    action: () => {
      handleGetCep("cep-first-input");
    },
  },
  {
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `Obrigado! <b>${userBasicName}</b>`;
    },
    nextStep: 24,
  },
  {
    sender: "bot",
    text: "Enquanto eu registro os seus dados...",
    nextStep: 25,
  },
  {
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Me informe a sua <b>data de nascimento</b>, por favor:"
        : "Me informe a sua <b>data de fundação</b> da empresa, por favor:";
    },
    inputType: "userNascimentoFundacao",
    nextStep: 26,
    action: async () => {
      const userNascimentoFundacao =
        useUserStore.getState().contrato.nascimentoFundacao;

      sendTag({
        event: "Cadastro Nascimento",
        nascimento: userNascimentoFundacao,
      });
    },
  },
  {
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `${userBasicName}, agora é só <b>confirmar</b> se está tudo certo:`;
    },
    nextStep: 27,
  },
  {
    sender: "bot",
    action: () => {
      const botMessages = useBotControlStore.getState().messages;
      const botCurrentStep = useBotControlStore.getState().currentStep;
      const consorcioBem = useConsorcioStore.getState().tipoBem;
      const userTipoBemNome = ProdutosNomes[consorcioBem];
      const consorcioTipoPlano = useConsorcioStore.getState().tipoPlano;
      const selectedPlano = useConsorcioStore.getState().selectedPlano;
      const TaxaAdmTotal =
        useConsorcioStore.getState().fetchedPlanDetails.taxaAdmTotal;
      const PlanoMeses = useConsorcioStore.getState().selectedPlano.planoMeses;
      const taxaMensal = (TaxaAdmTotal / PlanoMeses).toFixed(4);

      const newMessage = {
        sender: "bot",
        text: `
          <b>Consórcio para ${userTipoBemNome}</b><br /><br />
          <b>Tipo:</b> ${tipoPlanoNome[consorcioTipoPlano]}<br />
          <b> Plano:</b> ${selectedPlano.bemCredito.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}<br />
          em ${
            selectedPlano.planoMeses
          } parcelas de ${selectedPlano.planoValorParcela.toLocaleString(
          "pt-BR",
          { style: "currency", currency: "BRL" }
        )}<br />
          <b>Taxa mensal:</b> ${taxaMensal}%
        `,
      };

      useBotControlStore.setState({
        currentStep: botCurrentStep,
        messages: [...botMessages, newMessage],
      });
    },
    nextStep: 28,
  },
  {
    sender: "bot",
    action: () => {
      const botMessages = useBotControlStore.getState().messages;
      const botCurrentStep = useBotControlStore.getState().currentStep;
      const { nomeRazao, cpfCnpj, nascimentoFundacao, cep } =
        useUserStore.getState().contrato;
      const { telefone, email } = useUserStore.getState().basic;

      const { tipoPessoa } = useUserStore.getState().contrato;
      const isPessoaFisica = tipoPessoa === 0;

      const nomeRazaoLabel = isPessoaFisica ? "Nome" : "Razão Social";
      const cpfCnpjLabel = isPessoaFisica ? "CPF" : "CNPJ";
      const nascimentoFundacaoLabel = isPessoaFisica
        ? "Nascimento"
        : "Fundação";

      const newMessage = {
        sender: "bot",
        text: `
          <div class="message-ellipsis">
            <b> ${nomeRazaoLabel}:</b> ${nomeRazao}<br />
            <b> E-mail:</b> ${email}<br />
            <b> Celular:</b> ${addPhoneMask(telefone)}<br />
            <b> ${cpfCnpjLabel}:</b> ${cpfCnpj}<br />
            <b> ${nascimentoFundacaoLabel}:</b> ${nascimentoFundacao}<br />
            <b> CEP:</b> ${addCepMask(cep)}
          </div>
        `,
        title: `${nomeRazao} | ${email}`,
      };

      useBotControlStore.setState({
        currentStep: botCurrentStep,
        messages: [...botMessages, newMessage],
      });
    },
    nextStep: 29,
  },
  {
    sender: "bot",
    text: () => {
      const { empresaId, desconto } = useInitialConfigStore.getState();

      return `
              Para finalizar, preciso que você <b>leia</b> e <b>aceite</b> os termos do ${
                desconto
                  ? `<b>Regulamento Geral do Consórcio</b> e <b>Regulamento da Campanha.</b>`
                  : "<b>Regulamento Geral do Consórcio.</b>"
              }<br/><br/>
              <a class="link-highlight" href="https://chabot-cdn.mycon.com.br/mycon/contrato-participacao-grupo-consorcio-por-adesao.pdf" target="_blank">Regulamento Geral do Consórcio</a>
              ${
                desconto
                  ? `<br/><br/><a class="link-highlight" href="https://chatbot-cdn.mycon.com.br/regulamentos/consorcio-digital/${empresaId}.pdf" target="_blank">Regulamento da Campanha</a>`
                  : ""
              }
            `;
    },
    options: [
      {
        text: "CONFIRMAR",
        action: () => {
          handleGetProposta();
        },
      },
      { text: "DETALHES DO PLANO", nextStep: 31 },
      {
        text: "ALTERAR DADOS",
        nextStep: 30,
      },
      {
        text: "FALAR COM OS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
    nextStep: 31,
  },
  {
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `${userBasicName}, qual informação você precisa <b>alterar</b>?`;
    },
    options: [
      { text: "NOME", nextStep: 32 },
      { text: "E-MAIL", nextStep: 94 },
      { text: "CPF/CNPJ", nextStep: 95 },
      { text: "CELULAR", nextStep: 96 },
      { text: "NASCIMENTO", nextStep: 97 },
      { text: "CEP", nextStep: 98 },
      { text: "NENHUMA", nextStep: 26 },
    ],
    nextStep: 90,
  },
  {
    sender: "bot",
    options: [{ text: "CONFIRMAR", action: () => handleGetProposta() }],
    action: () => {
      const botMessages = useBotControlStore.getState().messages;
      const planInfosMessage = {
        id: "plan-infos",
        sender: "bot",
        text: GroupInfoOptions(),
      };

      useBotControlStore.setState({
        messages: [...botMessages, planInfosMessage],
      });
    },
  },
  {
    sender: "bot",
    text: "Altere o <b>nome completo</b> que vai no contrato.",
    inputType: "userContratoNomeRazao",
    nextStep: 26,
    action: () => {
      sendDataLayerUserDataUpdate();
    },
  },
  {
    sender: "bot",
    text: "<b>Aguarde</b> um momento...",
    nextStep: 34,
  },
  {
    sender: "bot",
    text: () => {
      const contrato = useConsorcioStore.getState().fetchedProposta.contrato;
      return `Pronto! Sua proposta nº <b>${contrato} foi efetivada com sucesso!</b>`;
    },
    nextStep: 35,
    action: async () => {
      const propostaPropostaID =
        useConsorcioStore.getState().fetchedProposta.propostaID;
      const propostaContrato =
        useConsorcioStore.getState().fetchedProposta.contrato;
      const propostaTipoCliente = useUserStore.getState().contrato.tipoCliente;

      sendTag({
        event: "Proposta Gerada",
        proposta_id: propostaPropostaID,
        contrato_id: propostaContrato,
        tipo_cliente: propostaTipoCliente,
      });
    },
  },
  {
    sender: "bot",
    text: "Para <b>concluir a contratação e garantir a sua oferta, efetue o pagamento da primeira parcela</b> e participe do próximo sorteio. 🥳",
    nextStep: 36,
  },
  {
    sender: "bot",
    text: () => {
      const parcela =
        useConsorcioStore.getState().selectedPlano.planoValorPagamento;

      return `Como prefere <b>pagar</b> a <b>1ª parcela</b> no valor de <b>${parcela.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" }
      )}</b>?`;
    },
    nextStep: 37,
  },
  {
    sender: "bot",
    text: "Escolha uma opção:",
    options: [
      {
        text: "PIX",
        nextStep: 63,
        action: () => {
          sendTag({ event: "PIX" });
        },
      },
      {
        text: "CARTÃO (3X SEM JUROS)",
        nextStep: 56,
        action: () => {
          sendTag({ event: "Cartão de Crédito" });
        },
      },
      {
        text: "BOLETO",
        nextStep: 38,
        action: () => {
          sendTag({ event: "Boleto Bancário" });
        },
      },
    ],
  },
  {
    name: "pagamento-boleto-registro",
    sender: "bot",
    text: "Certo! Estou <b>registrando</b> o seu boleto",
    action: async () => {
      handleGetPaymentBoleto();
    },
    component: (
      <img
        className="card-payment"
        src="https://chatbot-cdn.mycon.com.br/consorcio-digital/cards/assets/gerando-boleto.gif"
        alt="Pix Aprovado"
      />
    ),
  },
  {
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const basicUserName = useUserStore.getState().basic.nome;
      return `Prontinho, ${basicUserName}! Parabéns por iniciar a sua conquista!`;
    },
    nextStep: 40,
  },
  {
    sender: "bot",
    text: "Seleciona uma das opções abaixo para <b>realizar o pagamento</b>:",
    options: [
      {
        text: "COPIAR CÓDIGO",
        nextStep: 41,
        action: async () => {
          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "Boleto Bancário > Copia e Cola",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });
        },
      },
      {
        text: "ABRIR BOLETO",
        action: async () => {
          const boletoUrl = useUserStore.getState().payment.boleto.boletoURL;

          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "Boleto Bancário > Abrir Boleto",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });

          if (boletoUrl) {
            window.open(boletoUrl, "_blank");

            useBotControlStore.setState({
              currentStep: 44,
            });
          }
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Este é o <b>código</b> de barras do <b>boleto</b>:",
    nextStep: 42,
  },
  {
    sender: "bot",
    text: () => {
      return useUserStore.getState().payment.boleto.boletoCodigoBarras;
    },
    nextStep: 43,
  },
  {
    sender: "bot",
    text: "<b>Clique</b> no botão para <b>copiar o código</b> do boleto para <b>pagar</b> a sua primeira parcela.",
    options: [
      {
        text: "COPIAR CÓDIGO",
        nextStep: 44,
        action: () => {
          navigator.clipboard.writeText(
            useUserStore.getState().payment.boleto.boletoCodigoBarras
          );
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Agora entre no <b>APP do seu banco</b> e faça o <b>pagamento</b> o quanto antes ;)",
    nextStep: 45,
  },
  {
    sender: "bot",
    text: "Assim você já participa da <b>próxima assembleia</b> e quem sabe, já pode ser um dos <b>contemplados</b>.",
    options: [
      {
        text: "CONTINUAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("compra-finalizada").index,
          });
        },
      },
    ],
  },
  {
    name: "compra-finalizada",
    sender: "bot",
    text: "Você quer <b>comprar outra</b> cota?",
    options: [
      { text: "SIM", nextStep: 86 },
      {
        text: "NÃO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("pesquisa-inicio").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-inicio",
    sender: "bot",
    text: "Agora me responda uma curiosidade",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("pesquisa-midia").index,
      });
    },
  },
  {
    name: "pesquisa-midia",
    sender: "bot",
    text: "Como você ficou sabendo do <b>Mycon</b>?",
    options: [
      {
        text: "TV",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "TV",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "FACEBOOK",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "FACEBOOK",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "INSTAGRAM",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "INSTAGRAM",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "YOUTUBE",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "YOUTUBE",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "GOOGLE",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "GOOGLE",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "E-MAIL",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "E-MAIL",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "INDICAÇÃO",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "INDICAÇÃO",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "LIVE",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "LIVE",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "OUTROS",
        nextStep: 49,
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "OUTROS",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-nota",
    sender: "bot",
    text: "Qual a nota você dá para a experiência de compra do consórcio Mycon?",
    options: [
      {
        text: "1",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "1",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "2",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "2",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "3",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "3",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "4",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "4",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "5",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "5",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-humanos",
    sender: "bot",
    text: "Antes ou durante a simulação você precisou da ajuda do Time de Humanos?",
    options: [
      {
        text: "SIM",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              humano: "SIM",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos-pontuacao").index,
          });
        },
      },
      {
        text: "NÃO",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              humano: "NÃO",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-finalizada").index,
          });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Obrigado por avaliar!",
  },
  {
    name: "pesquisa-finalizada",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `<b>${userBasicName}</b>, muito obrigado :)`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("pesquisa-algo-mais").index,
      });

      const userPesquisa = useUserStore.getState().pesquisa;

      sendTag({
        event: "Pesquisa",
        ecommerce: { ...userPesquisa },
      });
    },
  },
  {
    name: "pesquisa-algo-mais",
    sender: "bot",
    text: "Se precisar de algo mais, estou aqui!",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("pesquisa-ate-mais").index,
      });
    },
  },
  {
    sender: "bot",
    text: "Você quer <b>comprar outra</b> cota?",
    options: [
      { text: "SIM", nextStep: 86 },
      {
        text: "NÃO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("pesquisa-inicio").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-ate-mais",
    sender: "bot",
    text: "Até mais!",
  },
  {
    sender: "bot",
    text: "Pagar no cartão é rápido e <b>100% seguro.</b>",
    nextStep: 57,
  },
  {
    sender: "bot",
    text: "Você pode pagar em <b>até 3x sem juros :)</b>",
    nextStep: 58,
  },
  {
    sender: "bot",
    text: () => {
      const parcela =
        useConsorcioStore.getState().selectedPlano.planoValorPagamento;

      return `O pagamento será realizado no valor de ${parcela.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" }
      )}.`;
    },
    nextStep: 59,
  },
  {
    sender: "bot",
    text: "Escolha uma das opções",
    options: [
      {
        text: "PAGAR NO CARTÃO",
        nextStep: null,
        action: () => {
          useBotControlStore.setState({
            showPaymentModal: true,
          });

          sendTag({
            event: "Dados Cartão Crédito",
            metodo_pagamento: "cartao_de_credito",
          });
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Aguarde um momento enquanto processamos o seu pagamento.",
    nextStep: 61,
  },
  {
    sender: "bot",
    text: "Prontinho! Estamos felizes em fazer parte do seu sonho.",
  },
  {
    sender: "bot",
    text: "<b>Seu pagamento cartão está em processamento.</b> Por favor, aguarde a confirmação que será enviada para o seu e-mail.",
    nextStep: 46,
    action: async () => {
      const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
      const purchaseBemCredito =
        useConsorcioStore.getState().selectedPlano.bemCredito;
      const purchaseBemNome =
        useConsorcioStore.getState().selectedPlano.bemNome;
      const purchasePlanoMeses =
        useConsorcioStore.getState().selectedPlano.planoMeses;
      const purchaseContrato =
        useConsorcioStore.getState().fetchedProposta.contrato;
      const purchaseCidade = useUserStore.getState().contrato.cidade;
      const purchaseIDC = useInitialConfigStore.getState().campanhaId;
      const purchaseBrand = useInitialConfigStore.getState().brand;

      sendTag({
        event: "purchase",
        ecommerce: {
          transaction_id: purchaseContrato, //Número do Contrato
          affiliation: purchaseBrand,
          value: purchaseBemCredito, //Valor do Crédito
          currency: "BRL",
          payment_type: "Cartão de Crédito",
          idc_compra: purchaseIDC,
          items: [
            {
              item_name: purchaseBemNome, //Nome do Bem
              item_id: purchaseCategoriaId, //ID daCategoria
              price: purchaseBemCredito, //Valor do Crédito
              item_brand: purchaseBrand,
              plano_meses: purchasePlanoMeses, //Número de Parcela
              location_id: purchaseCidade,
              index: 1,
              quantity: 1,
            },
          ],
        },
      });
    },
  },
  {
    name: "pagamento-pix-registro",
    sender: "bot",
    text: "Certo! Estou <b>registrando</b> o seu <b>Pix</b>",
    action: async () => {
      handleGetPaymentPix();
    },
    component: (
      <img
        className="card-payment"
        src="https://chatbot-cdn.mycon.com.br/consorcio-digital/cards/assets/pix.gif"
        alt="Pix Aprovado"
      />
    ),
  },
  {
    sender: "bot",
    isWaitingForFetch: true,
    text: "Prontinho! Parabéns por iniciar a sua conquista!",
    nextStep: 65,
  },
  {
    sender: "bot",
    text: "Como você quer o <b>código pix</b> para pagamento? ",
    options: [
      {
        text: "COPIA E COLA",
        nextStep: 78,
        action: async () => {
          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "PIX",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });
        },
      },
      {
        text: "QRCODE",
        nextStep: 66,
        action: async () => {
          const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
          const purchaseBemCredito =
            useConsorcioStore.getState().selectedPlano.bemCredito;
          const purchaseBemNome =
            useConsorcioStore.getState().selectedPlano.bemNome;
          const purchasePlanoMeses =
            useConsorcioStore.getState().selectedPlano.planoMeses;
          const purchaseContrato =
            useConsorcioStore.getState().fetchedProposta.contrato;
          const purchaseCidade = useUserStore.getState().contrato.cidade;
          const purchaseIDC = useInitialConfigStore.getState().campanhaId;
          const purchaseBrand = useInitialConfigStore.getState().brand;

          sendTag({
            event: "purchase",
            ecommerce: {
              transaction_id: purchaseContrato, //Número do Contrato
              affiliation: purchaseBrand,
              value: purchaseBemCredito, //Valor do Crédito
              currency: "BRL",
              payment_type: "PIX",
              idc_compra: purchaseIDC,
              items: [
                {
                  item_name: purchaseBemNome, //Nome do Bem
                  item_id: purchaseCategoriaId, //ID daCategoria
                  price: purchaseBemCredito, //Valor do Crédito
                  item_brand: purchaseBrand,
                  plano_meses: purchasePlanoMeses, //Número de Parcela
                  location_id: purchaseCidade,
                  index: 1,
                  quantity: 1,
                },
              ],
            },
          });
        },
      },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Este é o <b>QRCODE</b> do Pix para o pagamento da sua primeira parcela:",
    nextStep: 67,
  },
  {
    sender: "bot",
    action: () => {
      const messages = useBotControlStore.getState().messages;
      useBotControlStore.setState({
        messages: [
          ...messages,
          {
            id: "payment-success",
            sender: "bot",
            text: "",
            component: (
              <img
                style={{ width: "200px", height: "200px" }}
                src={useUserStore.getState().payment.pix.url}
                alt="Pix QR code"
              />
            ),
          },
        ],
      });
    },
    nextStep: 44,
  },
  {
    sender: "bot",
    text: "Abra o aplicativo do seu banco e  <b>fotografe o QRCODE</b>",
    nextStep: 69,
  },
  {
    sender: "bot",
    text: "<b>Depois que você pagar</b>, volte aqui para continuar:)",
    nextStep: 70,
  },
  {
    sender: "bot",
    text: "Escolha uma das opções:",
    options: [
      { text: "CONTINUAR", nextStep: 71 },
      { text: "COPIA E COLA", nextStep: 78 },
      {
        text: "ALTERAR FORMA DE PAGAMENTO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("alterar-pagamento").index,
          });
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "Você já <b>realizou o pagamento</b>?",
    options: [{ text: "SIM", nextStep: 72 }, { text: "NÃO" }],
  },
  {
    sender: "bot",
    text: "OPS! Ainda não recebemos a confirmação de que seu PIX foi pago.",
    nextStep: 73,
  },
  {
    sender: "bot",
    text: "Se você já efetuou o pagamento, o Banco irá me enviar a confirmação em breve.",
    options: [{ text: "CONTINUAR", nextStep: 74 }],
  },
  {
    sender: "bot",
    text: "Deixa eu consultar o seu banco novamente...",
    nextStep: 75,
  },
  {
    sender: "bot",
    text: "Enviei no seu e-mail essa confirmação e as demais orientações.",
    nextStep: 76,
  },
  {
    sender: "bot",
    text: "Você deu um passo importante para realizar as suas conquistas!",
    nextStep: 77,
  },
  {
    sender: "bot",
    text: "Vou <b>torcer muito</b> por você! :)",
    nextStep: 46,
  },
  {
    sender: "bot",
    text: "Este é o código do <b>PIX CÓPIA E COLA:</b>",
    nextStep: 79,
  },
  {
    sender: "bot",
    text: () => {
      return `<p style="line-break: anywhere;">${
        useUserStore.getState().payment.pix.qrCode
      }</p>`;
    },
    nextStep: 80,
  },
  {
    sender: "bot",
    text: "<b>Clique</b> no botão <b>para copiar o código</b> o PIX para pagar a sua primeira parcela.",
    options: [
      {
        text: "COPIAR",
        action: () => {
          const { qrCode } = useUserStore.getState().payment.pix;
          navigator.clipboard.writeText(qrCode);
        },
        nextStep: 81,
      },
    ],
  },
  {
    sender: "bot",
    text: "Código copiado com sucesso.",
    nextStep: 44,
  },
  {
    sender: "bot",
    text: "Agora abra o <b>aplicativo do seu banco</b>, escolha a opção:",
    nextStep: 83,
  },
  {
    sender: "bot",
    text: "Pagar com PIX Cópia e Cola.",
    nextStep: 84,
  },
  {
    sender: "bot",
    text: "<b>Depois que você pagar</b>, volte aqui para continuar:)",
    nextStep: 85,
  },
  {
    sender: "bot",
    text: "Escolha uma das opções:",
    options: [{ text: "CONTINUAR", nextStep: 71 }],
  },
  {
    sender: "bot",
    text: "Será com o mesmo valor de crédito e parcelas?",
    options: [
      {
        text: "NÃO",
        nextStep: 2,
        action: () => {
          resetStoreComprarNovaCota();
        },
      },
      { text: "SIM", nextStep: 26 },
    ],
  },
  {
    sender: "bot",
    text: "Desculpe, estamos passando por instabilidades. Tente novamente mais tarde.",
    options: [
      {
        text: "TENTAR NOVAMENTE",
        action: () => {
          resetStoreComprarNovaCota();
          useBotControlStore.setState({
            currentStep: 1,
          });
        },
      },
      {
        text: "FALAR COM NOSSOS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
  },
  {
    sender: "bot",
    text: "<b>Pagamento recusado :(</b> <br/><br/> O quê você deseja fazer?",
    options: [
      {
        text: "TENTAR NOVAMENTE",
        nextStep: 56,
        action: () => {
          sendTag({ event: "Cartão de Crédito" });
        },
      },
      {
        text: "PAGAR COM PIX",
        nextStep: 63,
        action: () => {
          sendTag({ event: "PIX" });
        },
      },
      {
        text: "PAGAR COM BOLETO",
        nextStep: 38,
        action: () => {
          sendTag({ event: "Boleto Bancário" });
        },
      },
      {
        text: "FALAR COM NOSSOS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
    component: (
      <img
        className="card-payment"
        src="https://chatbot-cdn.mycon.com.br/consorcio-digital/cards/assets/cartao-recusado.gif"
        alt="Cartão recusado"
      />
    ),
  },
  {
    id: "payment-pending",
    sender: "bot",
    text: "<b>Seu pagamento está sendo validado :)</b> <br><br> Aguarde a confirmação que será enviada para o seu e-mail. Qualquer dúvida, entre em contato com nosso time de especialistas.",
    options: [
      {
        text: "FALAR COM NOSSOS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
  },
  {
    id: "payment-confirmed",
    sender: "bot",
    text: () => {
      const { cartaoAutorizacao } = useUserStore.getState().payment;
      return `<b>Seu pagamento foi realizado com sucesso!</b> <br><br> nº ${cartaoAutorizacao}`;
    },
    action: async () => {
      const purchaseCategoriaId = useConsorcioStore.getState().categoriaId;
      const purchaseBemCredito =
        useConsorcioStore.getState().selectedPlano.bemCredito;
      const purchaseBemNome =
        useConsorcioStore.getState().selectedPlano.bemNome;
      const purchasePlanoMeses =
        useConsorcioStore.getState().selectedPlano.planoMeses;
      const purchaseContrato =
        useConsorcioStore.getState().fetchedProposta.contrato;
      const purchaseCidade = useUserStore.getState().contrato.cidade;
      const purchaseIDC = useInitialConfigStore.getState().campanhaId;
      const purchaseBrand = useInitialConfigStore.getState().brand;

      sendTag({
        event: "purchase",
        ecommerce: {
          transaction_id: purchaseContrato, //Número do Contrato
          affiliation: purchaseBrand,
          value: purchaseBemCredito, //Valor do Crédito
          currency: "BRL",
          payment_type: "Cartão de Crédito",
          idc_compra: purchaseIDC,
          items: [
            {
              item_name: purchaseBemNome, //Nome do Bem
              item_id: purchaseCategoriaId, //ID daCategoria
              price: purchaseBemCredito, //Valor do Crédito
              item_brand: purchaseBrand,
              plano_meses: purchasePlanoMeses, //Número de Parcela
              location_id: purchaseCidade,
              index: 1,
              quantity: 1,
            },
          ],
        },
      });

      useBotControlStore.setState({
        currentStep: findStep("compra-finalizada").index,
      });
    },
  },
  {
    id: "paga-metade-plan-type",
    sender: "bot",
    text: `Vou te explicar como funciona o Plano Paga Metade :)`,
    nextStep: 92,
  },
  {
    id: "paga-metade-plan-video",
    sender: "bot",
    text: "",
    component: (
      <video
        width="100%"
        poster="https://chabot-cdn.mycon.com.br/mycon/paga-metade.jpg"
        controls
      >
        <source src="https://player.vimeo.com/progressive_redirect/playback/876194542/rendition/240p/file.mp4?loc=external&log_user=0&signature=984a12a5a6ece049d771e9109dec48d79460e695aa11fb66b507145fe60d1e8f" />
      </video>
    ),
    options: [{ text: "CONTINUAR", nextStep: 9 }],
  },
  {
    id: "servico-tabela-completa",
    sender: "bot",
    text: "",
    component: null,
    action: () => {
      const botMessages = useBotControlStore.getState().messages;

      const serviceMessage = {
        sender: "bot",
        text: `Opa, vamos partir pra essa conquista. Separei <b>dois planos que tenho disponíveis para você escolher:</b>`,
      };
      const planoIntegralMessage = {
        sender: "bot",
        text: `<b>Plano Integral</b><br>Pague 100% do valor das suas parcelas até o final do prazo contratado em seu plano.`,
      };

      useBotControlStore.setState({
        messages: [...botMessages, serviceMessage, planoIntegralMessage],
      });
    },
    options: ServicesPlans(),
  },
  {
    sender: "bot",
    text: "Altere seu <b>e-mail</b>",
    inputType: "userEmail",
    nextStep: 26,
    action: () => {
      sendDataLayerUserDataUpdate();
    },
  },
  {
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Altere o seu <b>CPF </b>"
        : "Altere o <b>CNPJ </b>";
    },
    inputType: "userCpfCnpj",
    nextStep: 26,
    action: () => {
      sendDataLayerUserDataUpdate();
    },
  },
  {
    sender: "bot",
    text: "Altere seu <b>celular</b> (com DDD)",
    inputType: "userTelefone",
    nextStep: 26,
    action: () => {
      sendDataLayerUserDataUpdate();
    },
  },
  {
    sender: "bot",
    text: () => {
      const { tipoPessoa } = useUserStore.getState().contrato;

      return tipoPessoa === 0
        ? "Altere a <b>data de nascimento</b>:"
        : "Altere a <b>data de fundação</b>:";
    },
    inputType: "userNascimentoFundacao",
    nextStep: 26,
    action: () => {
      sendDataLayerUserDataUpdate();
    },
  },
  {
    sender: "bot",
    text: "Altere CEP:",
    inputType: "userCep",
    action: () => {
      handleGetCep("update");
      sendDataLayerUserDataUpdate();
    },
  },
  {
    name: "parcela-desconto-mensagem",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      const userTipoBem = useConsorcioStore.getState().tipoBem;
      const userTipoBemNome = ProdutosNomes[userTipoBem];
      const { empresaId } = useInitialConfigStore.getState();

      const isBlackFriday = empresaId === 97;

      return `<b>${userBasicName}!</b> olha que bacana! Como prometido, eu vou pagar <b>${
        isBlackFriday ? "60%" : "50%"
      } da 1ª parcela</b> desse plano que você escolheu: <b>Consórcio para ${userTipoBemNome}</b>`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("parcela-desconto-valor").index,
      });
    },
  },
  {
    name: "parcela-desconto-valor",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userValorPagamento =
        useConsorcioStore.getState().selectedPlano.planoValorPagamento;

      return `Nessa primeira parcela, você pagará apenas: <b>${userValorPagamento.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" }
      )}</b>`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: 18,
      });
    },
  },
  {
    sender: "bot",
    // text: "Agora, é preciso aceitar os termos do regulamento dessa campanha em relação a redução do crédito ou cancelamento posterior.",
    // nextStep: 102,
  },
  {
    sender: "bot",
    // text: () => {
    //   const userEmpresaId = useInitialConfigStore.getState().empresaId;
    //   return `<a class="link-highlight" href="https://chatbot-cdn.mycon.com.br/regulamentos/consorcio-digital/${userEmpresaId}.pdf" target="_blank">Ler Regulamento</a>`;
    // },
    // options: [{ text: "ACEITAR", nextStep: 18 }],
  },
  {
    name: "sem-renda-extra",
    sender: "bot",
    text: () => `Então você precisa escolher um <b>crédito menor.</b>`,
    inputType: "userCreditValue",
    nextStep: 17,
  },
  {
    name: "com-renda-extra",
    sender: "bot",
    text: () => `Informe a <b>renda extra</b> ou <b>familiar</b>:`,
    inputType: "userRendaExtra",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("renda-extra-adicinada").index,
      });
    },
  },
  {
    name: "renda-extra-adicinada",
    sender: "bot",
    text: () =>
      `Ótimo 😀! Agora vou somar as rendas para encontrar a <b>parcela ideal</b>, tá bem?`,
    options: [
      {
        text: "Tá bem :)",
        action: () => {
          const botMessages = useBotControlStore.getState().messages;

          const newMessage = {
            sender: "bot",
            text: "Vou te mostrar planos com <b>parcelas que cabem no seu bolso</b> e que não comprometem mais de <b>30%</b> da sua renda 😉.",
          };

          useBotControlStore.setState({
            messages: [...botMessages, newMessage],
          });

          useBotControlStore.setState({
            currentStep: 17,
          });

          const userRendaMensal = useUserStore.getState().basic.rendaMensal;
          const userRendaExtra = useUserStore.getState().basic.rendaExtra;

          sendTag({
            event: "Renda Extra",
            renda: userRendaMensal,
            renda_extra: userRendaExtra,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-humanos-pontuacao",
    sender: "bot",
    text: `Qual nota você dá para o <b>atendimento</b> do Time de Humanos?`,
    options: [
      {
        text: "1",
        nextStep: 52,
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "1",
            },
          });
        },
      },
      {
        text: "2",
        nextStep: 52,
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "2",
            },
          });
        },
      },
      {
        text: "3",
        nextStep: 52,
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "3",
            },
          });
        },
      },
      {
        text: "4",
        nextStep: 52,
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "4",
            },
          });
        },
      },
      {
        text: "5",
        nextStep: 52,
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "5",
            },
          });
        },
      },
    ],
  },
  {
    name: "plans-list-select",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `${userBasicName}! selecionei os <b>melhores planos</b> pra você. 😀`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("plans-list-carousel-show-message").index,
      });
    },
  },
  {
    name: "plans-list-carousel-show-message",
    sender: "bot",
    isWaitingForFetch: true,
    text: () => "Selecione o plano ideal para você:",
    useCarouselPlan: true,
  },
  {
    name: "basico-valor-renda",
    sender: "bot",
    text: "Digite o <b>valor da renda</b>:",
    inputType: "userRendaMensal",
    action: () => {
      useBotControlStore.setState({
        currentStep: 13,
      });
    },
  },
  {
    name: "alterar-pagamento",
    sender: "bot",
    text: "Escolha uma opção:",
    options: [
      {
        text: "PIX",
        action: () => {
          sendTag({
            event: "Metodo de Pagamento Alterado",
            metodo_pagamento: "pix",
          });

          useBotControlStore.setState({
            currentStep: findStep("pagamento-pix-registro").index,
          });
        },
      },
      {
        text: "CARTÃO (3X SEM JUROS)",
        nextStep: 56,
        action: () => {
          sendTag({
            event: "Metodo de Pagamento Alterado",
            metodo_pagamento: "cartao_de_credito",
          });
        },
      },
      {
        text: "BOLETO",
        action: () => {
          sendTag({
            event: "Metodo de Pagamento Alterado",
            metodo_pagamento: "boleto_bancario",
          });

          useBotControlStore.setState({
            currentStep: findStep("pagamento-boleto-registro").index,
          });
        },
      },
    ],
  },
  ...errorSteps,
  ...pagamentoPixSteps,
  ...pagamentoBoletoSteps,
  ...cepErrorSteps,
];

export default steps;
