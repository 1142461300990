import useUserStore from "../../../storeModules/useUserStore";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import findStep from "./findStep";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../../../utils/setLoadingMessage";
import { getCep } from "../../../api/requests";
import sendTag from "../../../utils/sendTag";

const cepErrorSteps = [
  {
    name: "cep-error",
    sender: "bot",
    text: "",
    isWaitingForFetch: true,
    action: () => {
      const { cepErrorAt } = useUserStore.getState();
      const nextStep =
        cepErrorAt === "cep-first-input"
          ? 23
          : cepErrorAt === "update"
          ? 26
          : 26;
      const { logradouro, bairro } = useUserStore.getState().contrato;

      if (!logradouro) {
        useBotControlStore.setState({
          currentStep: findStep("cep-add-street").index,
        });

        return;
      }

      if (!bairro) {
        useBotControlStore.setState({
          currentStep: findStep("cep-add-neighborhood").index,
        });

        return;
      }

      useBotControlStore.setState({
        currentStep: nextStep,
      });
    },
  },
  {
    name: "cep-add-street",
    sender: "bot",
    inputType: "userStreet",
    text: "Insira o nome da <b>rua.</b>",
    isWaitingForFetch: true,
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("cep-error").index,
      });
    },
  },
  {
    name: "cep-add-neighborhood",
    sender: "bot",
    inputType: "userNeighborhood",
    text: "Insira o nome do <b>bairro.</b>",
    isWaitingForFetch: true,
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("cep-error").index,
      });
    },
  },
  {
    name: "cep-not-found",
    sender: "bot",
    text: "<b>Ops!</b> não encontrei esse CEP.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("cep-try-again").index,
      });
    },
  },
  {
    name: "cep-try-again",
    sender: "bot",
    text: `<b>Corrija o CEP</b> para tentar novamente.`,
    inputType: "userCep",
    action: async () => {
      const { cepErrorAt } = useUserStore.getState();
      const nextStep =
        cepErrorAt === "cep-first-input"
          ? 23
          : cepErrorAt === "update"
          ? 26
          : 26;
      setLoadingMessage();

      setTimeout(() => {
        getCep()
          .then((response) => {
            const address = response.data.data;
            const userCep = useUserStore.getState().contrato.cep;
            const userContratoData = useUserStore.getState().contrato;
            const userBasicData = useUserStore.getState().basic;
            const userLeadData = useUserStore.getState().lead;

            sendTag({
              event: "Cadastro CEP",
              cep: userCep,
            });

            if (!address) {
              useBotControlStore.setState({
                currentStep: findStep("cep-not-found").index,
              });
              return;
            }

            if (!address.logradouro || !address.bairro) {
              useBotControlStore.setState({
                currentStep: findStep("cep-error").index,
              });
              return;
            }

            if (address && address.cep) {
              useUserStore.setState({
                basic: {
                  ...userBasicData,
                },
                lead: {
                  ...userLeadData,
                },
                contrato: {
                  ...userContratoData,
                  bairro: address.bairro,
                  cep: address.cep,
                  cidade: address.cidade,
                  complemento: address.complemento,
                  estado: address.estado,
                  logradouro: address.logradouro,
                  numero: address.numero,
                },
              });
            }

            useBotControlStore.setState({ currentStep: nextStep });

            return response;
          })
          .catch(() => {
            useBotControlStore.setState({
              currentStep: findStep("cep-not-found").index,
            });
          })
          .finally(() => {
            removeLoadingMessage();
          });
      }, 500);
    },
  },
];

export default cepErrorSteps;
