import sendTag from "../../../../utils/sendTag";

const pagamentoPixSteps = [
  {
    name: "pagamento-pix-tentar-novamente",
    sender: "bot",
    text: `<b>OPS! </b>Tivemos um problema para gerar o PIX! O quê você deseja fazer?`,
    options: [
      {
        text: "TENTAR NOVAMENTE",
        nextStep: 63,
        action: () => {
          sendTag({ event: "PIX" });
        },
      },
      {
        text: "PAGAR COM CARTÃO",
        nextStep: 56,
        action: () => {
          sendTag({ event: "Cartão de Crédito" });
        },
      },
      {
        text: "PAGAR COM BOLETO",
        nextStep: 38,
        action: () => {
          sendTag({ event: "Boleto Bancário" });
        },
      },
      {
        text: "FALAR COM NOSSOS ESPECIALISTAS",
        action: () => {
          window.open(
            "https://api.whatsapp.com/send?1=pt_BR&phone=5511940761248",
            "_blank"
          );
        },
      },
    ],
  },
];

export default pagamentoPixSteps;
