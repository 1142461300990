import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./screens/errorPage";
import { TipoBotEnum } from "./utils/constants";

const BOT_ROUTE_CONFIG = {
  DEFAULT: {
    botType: TipoBotEnum.MYCON,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
  },
  LIVE: {
    botType: TipoBotEnum.LIVE,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
  },
  ANIVERSARIO: {
    botType: TipoBotEnum.ANIVERSARIO,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
  },
  PERFORMANCE: {
    botType: TipoBotEnum.PERFORMANCE,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
  },
  ANIVERSARIO_FINAL_DE_SEMANA: {
    botType: TipoBotEnum.ANIVERSARIO_FINAL_DE_SEMANA,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
  },
  MES_BLACKFRIDAY: {
    botType: TipoBotEnum.MES_BLACKFRIDAY,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
  },
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App {...BOT_ROUTE_CONFIG.MES_BLACKFRIDAY} />,
  },
  {
    path: "/oferta",
    element: <App {...BOT_ROUTE_CONFIG.MES_BLACKFRIDAY} />,
  },
  {
    path: "/final-de-semana",
    element: <App {...BOT_ROUTE_CONFIG.ANIVERSARIO_FINAL_DE_SEMANA} />,
  },
  {
    path: "/PERFORMANCE",
    element: <App {...BOT_ROUTE_CONFIG.PERFORMANCE} />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
  {
    path: "/app",
    element: <App {...BOT_ROUTE_CONFIG.MES_BLACKFRIDAY} />,
  },
]);

export default router;
