import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const userInitialState = {
  basic: {
    nome: "",
    valorCredito: "",
    rendaMensal: 0,
    rendaExtra: 0,
    email: "",
    telefone: "",
  },
  contrato: {
    empresaID: 0,
    bemID: 0,
    planoID: 0,
    leadID: "",
    planoMeses: 0,
    tipoPessoa: 0,
    nomeRazao: "",
    cpfCnpj: "",
    rgIe: "",
    nascimentoFundacao: "",
    email: "",
    telefoneResidencial: "",
    telefoneCelular: "",
    rendaMensal: 0,
    autoriza: true,
    aceiteRegulamento: true,
    nomeEmpresa: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cep: "",
    cidade: "",
    estado: "",
    tipoCliente: "",
  },
  lead: {
    LeadID: "",
    LeadToken: "",
  },
  payment: {
    cartaoAutorizacao: "",
    boleto: {
      Contrato: "",
      BoletoCodigoBarras: "",
      BoletoURL: "",
      BoletoVencimento: "",
    },
    pix: {
      Contrato: "",
      PixQrCode: "",
      PixURL: "",
      PixVencimento: "",
    },
  },
  pesquisa: {
    midia: "",
    csatcompra: "",
    humano: "",
    csatinsidesales: "",
  },
  cepErrorAt: "",
  isSimulateAgain: false,
};

export const useUserStore = create()(
  devtools(
    immer((set) => ({
      ...userInitialState,
      setUserBasicInfo(value, type) {
        set(
          (state) => {
            state.basic[type] = value;
          },
          true,
          `setUserBasicInfo - ${type}`
        );
      },
      setUserContratoInfo(value, type) {
        set(
          (state) => {
            state.contrato[type] = value;
          },
          true,
          `setUserContratoInfo - ${type}`
        );
      },
      setLeadInfo(value) {
        set(
          (state) => {
            state.lead = value;
          },
          true,
          `setLeadInfo - saveLead`
        );
      },
      setPayment(value, type) {
        set(
          (state) => {
            state.payment[type] = value;
          },
          true,
          `setPayment - ${type}`
        );
      },
      setIsSimulateAgain(value) {
        set(
          (state) => {
            state.isSimulateAgain = value;
          },
          true,
          `setIsSimulateAgain - ${value}`
        );
      },
    })),
    { enabled: true, name: "useUserStore" }
  )
);

export default useUserStore;
