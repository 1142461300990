import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";

export const initialConfigState = {
  plan: "",
  campanhaId: 0,
  compraId: 0,
  empresaId: 0,
  tipo: 1,
  bannerSrc: "",
  desconto: false,
  tipoTabela: "integral",
  brand: "Site Mycon",
  botType: "",
  referralcode: "",
  fakeLoad: 1500,
  isApp: false,
};

export const useInitialConfigStore = create()(
  immer(
    devtools(
      (set) => ({
        ...initialConfigState,
        setInitialConfigStore: (value, type) => {
          set(
            (state) => {
              state[type] = value;
            },
            false,
            `setInitialConfigStore - ${type}`
          );
        },
      }),
      { enabled: true, name: "useInitialConfigStore" }
    )
  )
);

export default useInitialConfigStore;
