import React, { useEffect } from "react";
import sendTag from "../../utils/sendTag";

const GoogleTagManager = ({ gtmId }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    sendTag({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    return () => {
      document.head.removeChild(script);
    };
  }, [gtmId]);

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
        title="Google Tag Manager"
      ></iframe>
    </noscript>
  );
};

export default GoogleTagManager;
