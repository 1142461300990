/* eslint-disable no-undef */
import useInitialConfigStore from "../storeModules/useInitialConfigStore";

const sendTag = (tag) => {
  const { isApp } = useInitialConfigStore.getState();

  window.dataLayer.push(tag);

  if (
    (typeof AppSendTag === "function" || typeof AppSendTag === "object") &&
    isApp
  ) {
    AppSendTag.postMessage(tag.event);
  }
};

export default sendTag;
